<template>
    <div class="login-page">
        <div class="login-container">
            <h1><img src="@/assets/images/login-logo.png" />科房集团供应商报价系统</h1>
            <div class="form-container">
                <div class="left-img"><img src="@/assets/images/left-img.png" /></div>
                <el-form ref="loginForm" :model="loginForm" :rules="loginRules" autocomplete="off" >
                    <div class="title">登录</div>
                    <el-form-item prop="supply_code" class="form-item_code">
                        <el-input v-model="loginForm.supply_code" tabindex="1" placeholder="请输入供应商编码"></el-input>
                    </el-form-item>
                    <el-form-item prop="password" class="form-item_psd">
                        <el-input v-model="loginForm.password" tabindex="2" type="password" placeholder="请输入密码"></el-input>
                    </el-form-item>
                    <el-form-item prop="captcha" class="form-captcha">
                        <el-input v-model="loginForm.captcha" tabindex="3" @keyup.enter.native="handleLogin" placeholder="请输入验证码"></el-input>
                        <img class="img-captcha" :src="captchaUrl" @click="refreshCaptcha()" />
                    </el-form-item>
                    <el-form-item style="margin-bottom:10px">
                        <el-button :loading="loading" type="primary" style="width:100%;" @click.native.prevent="handleLogin">登录</el-button>
                    </el-form-item>
                    <div class="tips"><img src="@/assets/images/mess.png" />供应商首次登陆，请联系采购员获取供应商编号。</div>
                </el-form>
            </div>
                
        </div>
        


        <div class="bottom"><a href="https://beian.miit.gov.cn" target="_blank">沪ICP备09011892号-3</a>    Copyright© KEFAN Co.,Ltd All Rights Reserved 上海科杨房地产置业有限公司</div>
    </div>
</template>
<script>
import { isLoginAuth, supplierLogin, logout } from "@/api";
import { setCookie } from '@/utils/cookie'

export default {
    name: 'login',
    data() {
        return {
            loading: false,
            captchaUrl: '/api/site/captcha',
            loginForm: {
                supply_code: '',
                password: '',
                captcha: ''
            },
            loginRules: {
                supply_code: [{ required: true, trigger: 'blur', message: '请输入用户名称' }],
                password: [{ required: true, trigger: 'blur', message: '请输入密码' }],
                captcha: [{ required: true, trigger: 'blur', message: '请输入验证码' }],
            },
        }
    },
    
    methods: {
        wwlogout(){
            logout()
        },
        refreshCaptcha(){
            this.captchaUrl = this.captchaUrl + '?t=' + Math.random()
        },
        handleLogin() {
            this.$refs.loginForm.validate(valid => {
                if (valid) {
                    this.loading = true
                    supplierLogin(this.loginForm).then(res => {
                        const userInfo = res.data.user;
                        setCookie(true)
                        this.$store.dispatch('user/setInfo', userInfo)

                        if(userInfo.status===0){
                            this.$router.push({name: 'AddInformation'})
                        } else if(userInfo.status===1 || userInfo.status===5 || userInfo.status===6){
                            this.$router.push({name: 'Information'})
                        } else {
                            this.$router.push({name: 'priceList'})
                        }
                    }).catch(err => {
                        // console.log(err,'异常')
                    }).finally(()=>{
                        this.refreshCaptcha();
                        this.loading = false
                    })
                }
            });
        }
          
    }
}
</script>
<style  lang="scss" scoped>
.bottom{
    font-size: 14px;
    color: #bbb;
    line-height: 21px;
    position: fixed;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    a{
        margin-right: 20px;
        color: #bbb;
        text-decoration: none;
        &:hover{
            color: #222;
        }
    }
}
.login-page{
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background: url('../../assets/images/login-bg.png') no-repeat;
    background-size: 100% 100%;
    background-position: center top;
    background-color: #fff;
    min-height: 558px;
    overflow-y: auto;
}
.login-container{
    width: percentage(720/1920);
    top: 50%;
    left: 50%;
    position: absolute;
    margin-top: percentage(-40/1920);
    // width:720px;
    // margin-left: -(720px/2 - 50px);
    font-family: '思源黑体 CN Bold Bold';
    transform: translate(-50%, -50%);
    

    h1{
        color: #fff;
        font-size: 34px;
        font-weight: bold;
        text-align: center;
        margin: 0;
        padding:0;
        line-height: 59px;
        margin-bottom: 25px;
        img{
            width:78px;
            vertical-align: middle;
            margin-right: percentage(48/720);
        }
    }
    .form-container{
        padding: percentage(45/720) percentage(66/720) percentage(45/720) percentage(280/720);
        background-color: #fff;
        position: relative;
        box-shadow: 0 21px 21px -7px rgb(242 227 227 / 62%);

        .title{
            color: #333;
            font-size: 22px;
            font-weight: bold;
            text-align: center;
            margin-bottom: percentage(36/720);
        }
        .left-img{
            bottom: percentage(-250/720);
            width: percentage(514/720);
            left: percentage(-260/720);
            max-width: 514px;
            position: absolute;
            z-index: 10;
            img{
                width: 100%;
            }
        }

        .el-form{
            ::v-deep{
                .el-form-item{
                    margin-bottom: 20px;
                }

                .el-input{
                    max-width: inherit;
                    padding-left: 44px;
                    height: 50px;
                    border: 1px solid #B6BACE;
                    background-position: 16px center;
                    background-repeat: no-repeat;
                }
                .el-input__inner{
                    height: 48px;
                    line-height: 48px;
                    border:0px;
                    padding:0;
                    border-radius: 0;
                }

                .form-item_code{
                    .el-input{
                        background-image: url('../../assets/images/input-code.png');
                        
                    }
                }
                .form-item_psd{
                    .el-input{
                        background-image: url('../../assets/images/input-psd.png');
                    }
                }

                .form-captcha{
                   .el-form-item__content{ padding-right: percentage(140/347);}
                    .el-input{
                        padding-left: 18px;
                    }
                    .img-captcha{
                        top: 0;
                        right: 0;
                        position: absolute;
                        width: percentage(132/347);
                        height: 100%;
                    }
                }

                .el-button{
                    margin-top: (16/347);
                    width: 350px;
                    height: 54px;
                    line-height: 54px;
                    background: #B61814;
                    padding:0;
                    font-size: 16px;
                }
                .tips{
                    color: #333;
                    font-size: 14px;
                    line-height: 21px;
                    img{
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1680px) {
    .login-page{
        ::v-deep{
            .form-container{
                padding: percentage(45/720) percentage(40/720) percentage(45/720) percentage(240/720);

                .el-form{
                    .el-input{
                        height: 46px;
                    }
                    .el-input__inner{
                        height: 44px;
                        line-height: 44px;
                    }
                    .tips{
                        font-size: 12px;
                    }
                }
            }
            
        }
    }
}

</style>